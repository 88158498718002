<template>
  <div class="vld-parent cyb-login base-page">
    <login-form :hasForm="false" pageclass="darker">
      <template #text-content>
        <div class="cyb-login-misc splash">
          <div class="text-box">
            <div class="logo"><img src="/images/thinfinity-white.svg" /></div>
            <div class="content">
              <div class="base-template">
                <h1><span>{{ $t('dzone.soSorry') }}</span></h1>
                <p>{{ $t('dzone.notAvailable') }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </login-form>
  </div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue';
export default {
  data() {
    return {
    };
  },
  components: {
    LoginForm
  }
};
</script>